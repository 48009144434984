<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人力法宝</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">AI咨询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:10px">
            <div title="咨询内容" class="searchboxItem ci-full">
              <span class="itemLabel">咨询内容:</span>
              <el-input v-model="searchForm.content" type="text" size="small" placeholder="请输入问题描述" clearable />
            </div>
            <div title="手机号码" class="searchboxItem ci-full">
              <span class="itemLabel">手机号码:</span>
              <el-input v-model="searchForm.mobile" type="text" size="small" placeholder="请输入手机号码" clearable />
            </div>
            <div title="状态" class="searchboxItem ci-full">
              <span class="itemLabel">评价状态:</span>
              <el-select v-model="searchForm.state" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in resolutionStateList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="60px" fixed="left" :index="indexMethod" />
              <el-table-column label="手机号" align="left" prop="mobile" show-overflow-tooltip min-width="100px" />
              <el-table-column label="咨询内容" align="left" prop="content" show-overflow-tooltip min-width="200px" />
              <el-table-column label="问题状态" align="center" prop="stateName" show-overflow-tooltip min-width="50px" />
              <el-table-column label="咨询时间" align="center" prop="createTime" width="150px" />
              <el-table-column label="操作" align="center" width="120px" fixed="right">
                <template slot-scope="{ row }">
                  <el-button size="small" type="text" style="padding:0px 5px" @click="showInfo(row)" >查看</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>

    <el-dialog title="AI咨询详情" width="800px" :visible.sync="showState" :before-close="closeInfo">
      <div class="container-css">
        <div class="item-content">
          <div class="title">咨询内容</div>
          <div class="overview">{{ formInfo.content }}</div>
        </div>
        <div class="item-content">
          <div class="title">AI解答</div>
          <div class="content" v-html="formInfo.text" ></div>
        </div>
        <div class="item-content" v-if="formInfo.legalBasis" >
          <div class="title">法律依据</div>
          <div class="overview" v-for="item in formInfo.legalBasis" >{{ item.content }}</div>
        </div>
        <div class="item-content" v-if="formInfo.referenceCase" >
          <div class="title">参考案例</div>
          <div class="overview" v-for="item in formInfo.referenceCase" >{{ item.exampleTitle }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "manpower_consult_consultAiList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 搜索条件
      searchForm: {
        mobile: '',    //手机号码
        content: '',   //问题描述
        state: '',     //状态
      },
      resolutionStateList: [], //状态字典
      showState: false,      //是否显示信息
      formInfo: {
        id: "",              //记录id
        content: "",         //咨询内容
        text: "",            //AI解答
        legalBasis: [],      //法律法规
        referenceCase: [],   //参考案例
      },
    };
  },
  computed: {},
  created() { },
  mounted() {
    this.getDictionary();
  },
  methods: {
    //获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      }
      if (this.searchForm.content) {
        params.content = this.searchForm.content;
      }
      if (this.searchForm.mobile) {
        params.mobile = this.searchForm.mobile;
      }
      if (this.searchForm.state) {
        params.resolutionState = this.searchForm.state;
      }
      this.doFetch({
        url: "/biz/csp/chatting/v2/mgt/consult/page",
        params,
        pageNum,
      }, true, 9);
      setTimeout(()=>{
        console.log(this.tableData)
      },1000)
    },
    //获取字典
    getDictionary() {
      // 工单状态
      let arr1 = this.$setDictionary("RESOLUTION_STATE", "list");
      for (const key in arr1) {
        this.resolutionStateList.push({
          value: key,
          label: arr1[key],
        });
      }
    },
    showInfo(row) {
      this.formInfo = row;
      this.showState = true;
    },
    closeInfo() {
      this.showState = false;
      this.formInfo = {
        id: "",              //记录id
        content: "",         //咨询内容
        text: "",            //AI解答
        legalBasis: [],      //法律法规
        referenceCase: [],   //参考案例
      }
    },

  },
}
</script>
<style lang="less" scoped>
.container-css {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;

  .item-content {
    .title {
      font-weight: 600;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      padding-top: 10px;
      padding-bottom: 12px;
    }
    .overview {
      padding-left: 8px;
      padding-bottom: 5px;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
    .content {
      height: 200px;
      padding-left: 8px;
      overflow-x: hidden;
      overflow-y: auto;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }
}

</style>